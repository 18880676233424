<template>
  <div id="adhoc-lounge">
    <div>
      <div class="results_expanded" v-if="adhoc_lounge_products.length > 0">
        <template v-for="item in adhoc_lounge_products" :key="item">
          <div class="results_expanded_wrapper">
            <template v-if="item && item.lounge_image_main_en">
              <img :src="item.lounge_image_main_en" class="results_top_image" />
            </template>

            <div class="results_expanded_title_description">
              <div class="results_expanded_title">
                {{ getrans(item.display_name) }}
              </div>
              <p
                class="results_expanded_description"
                v-html="getrans(decodeURI(item.description))"
              ></p>
              <h6>Volume description</h6>
              <p
                class="results_expanded_description"
                v-html="getrans(decodeURI(item.volume_description))"
              ></p>              
            </div>
            <div class="results_expanded_price_quantity">
              <div class="results_expanded_price">
                <span>
                  <b>
                    {{ currency }}
                    {{ parseFloat(Number(item.rate)).toFixed(2) }}
                  </b>
                </span>
              </div>

              <div class="results_expanded_quantity">
                <div class="input-tickbox">
                  <label :for="`result-tickbox-${item.product_id}`">
                    <input
                      :key="item.product_id"
                      style="display: none"
                      @click="click_adhoc_lounge(item, $event)"
                      type="checkbox"
                      :id="`result-tickbox-${item.product_id}`"
                      :name="`result-tickbox-${item.product_id}`"
                      required="true"
                      
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";

export default {
  name: "ResultsADHOC_lounge",
  props: {
    adhoc_lounge_products: undefined,
    lang: undefined,
    translations: undefined,
    brandSlug: undefined,
    currency: undefined,
    lounge_id: undefined,
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    click_adhoc_lounge(item, e) {
      console.log(`lounge id: ${this.lounge_id}`, item);
      console.log(`lounge e: ${e.target.checked}`);

      let current_cart = sessionStorage.getItem("adhoc_lounge_cart_temp");
      current_cart = JSON.parse(current_cart) || {};

      //let new_store = [...current_cart, item];

      if (!current_cart[this.lounge_id]) {
        current_cart[this.lounge_id] = {};
      }
      let cart_item = {};
      cart_item.pr_id = item.product_id;
      cart_item.pr_name = item.product_name;
      cart_item.pr_price = item.rate;
      cart_item.quantity = 1;
      cart_item.pr_type = "M";  
      cart_item.allow_quantity_selection = item.allow_quantity_selection; 

      if (e.target.checked) {
        current_cart[this.lounge_id][item.product_id] = cart_item;
      } else {
         delete current_cart[this.lounge_id][item.product_id];
      }
 
   
    let adhoc_lounge_cart_total = 0;
    
    for (const key in current_cart) {
        for (const innerKey in current_cart[key]) {
          adhoc_lounge_cart_total += current_cart[key][innerKey].pr_price * current_cart[key][innerKey].quantity;
        }
    }
    
      console.log(adhoc_lounge_cart_total);
      current_cart = JSON.stringify(current_cart);
      console.log('current_cart',current_cart);
      sessionStorage.setItem("adhoc_lounge_cart_temp", current_cart);
      sessionStorage.setItem("adhoc_lounge_cart_total", adhoc_lounge_cart_total);


    },

    set_checked(product_id) {
      let current_cart = sessionStorage.getItem("adhoc_lounge_selected");
      current_cart = JSON.parse(current_cart) || {};
      if (current_cart[this.lounge_id] && current_cart[this.lounge_id][product_id] === true) {
        return true;
      }
      return false;
    },




    getrans(string) {
      var s = md5(string);
      var ss = this.brandSlug + "-" + this.lang.toUpperCase() + "-" + s;
      //console.log(ss);
      return this.translations[ss] || string;
    },
    // getrans(string) {
    //   var s = md5(string);
    //   var ss = this.setbrandid + "-" + this.lang.toUpperCase() + "-" + s;
    //   //console.log(ss);
    //   return this.translations[ss] || string;
    // },
  },
  created() {},
  mounted() {

    //sessionStorage.setItem("adhoc_lounge_selected", '');

  },

  updated() {},
};
</script>

<style lang="scss">
.results_expanded_description ul {
  padding: 1rem;
  margin: 0;
  li {
    margin-bottom: 0.5rem;
  }
}
</style>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

.results-fade.not-loaded {
  opacity: 0;
}

.results-fade {
  overflow: hidden;
  opacity: 1;
  transition: opacity 1s ease;
}

.results {
  max-width: calc(1280px + 2rem);
  // padding: 0 1rem;
  // margin: 3rem auto;
  background: #fff;

  &-container {
    margin-top: 3.75rem;
    @media screen and (max-width: 768px) {
      margin-top: 1rem;
    }
  }

  &_box {
    max-width: 1280px;
    margin: 0 auto 1rem;
    @media screen and (max-width: 767px) {
      margin: 0 auto 1rem;
    }
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #dfdfdf;
  }
  &_top {
    display: flex;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    &_image_title_description {
      width: 100%;
      display: flex;
    }
    &_image {
      flex: 0;
      align-self: flex-start;
      max-width: 175px;
      @media screen and (max-width: 959px) {
        width: 180px;
      }
      @media screen and (max-width: 767px) {
        width: 33%;
        display: none;
      }
      height: auto;
      background: #ddd;
    }
    &_title_description {
      width: 100%;
      padding: 1.5rem;
      @media screen and (max-width: 959px) {
        padding: 1rem;
      }
    }
    &_title {
      font-size: 1.25rem;
      color: #1a2a40;
    }
    &_description {
      font-size: 1rem;
      color: #707070;
    }
    &_price_button {
      width: auto;
    }
    &_price_button {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 767px) {
        flex-direction: row;
      }
      justify-content: space-between;
      align-items: center;
      min-width: 175px;
      border-left: 1px solid #ddd;

      del {
        font-size: 1.2rem;
        color: #999;
        display: inline-block;
        padding-right: 0.5rem;
      }

      .notes {
        font-size: 1.2rem;
        color: #999;
        display: block;
        font-size: 1rem;
      }
    }
    &_price {
      padding: 1rem 1rem;
      flex: 1;
      display: flex;
      justify-content: center;

      @media screen and (min-width: 767.98px) {
        > div {
          display: flex;
          flex-direction: column;
        }
      }

      @media screen and (max-width: 767px) {
        justify-content: flex-start;
        padding: 0rem 1rem;
        align-items: baseline;
      }
      align-items: center;
      color: #707070;
      font-size: 1.25rem;
      text-align: center;
      b {
        font-size: 1.75rem;
        color: #1a2a40;
      }
      @media screen and (max-width: 767px) {
        justify-content: center;
        align-items: baseline;
        padding: 0rem 1rem;
        font-size: 1rem;
        b {
          font-size: 1.3rem;
          color: #1a2a40;
        }
      }
    }
    &_button {
      flex: 0;
      @media screen and (max-width: 767px) {
        flex: 1;
      }
      width: 100%;
      button {
        padding: 1.25rem 1rem;
        @media screen and (max-width: 959px) {
          padding: 1rem 1rem;
          font-size: 1rem;
        }
        width: 100%;
        background-color: var(--result_button_expand_background, #034a94);
        color: var(--result_button_expand_font_color, #ffffff);
        border: none;
        font-size: var(--result_button_expand_font_size, 1.325rem);
        font-weight: var(--result_button_expand_font_weight, bold);
        font-family: var(--result_button_expand_font_family, inherit);
      }
      button:disabled {
        filter: saturate(0);
        cursor: not-allowed;
      }
    }
  }
}

.results_expanded {
  &_wrapper {
    display: flex;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    border-top: 1px solid #dfdfdf;
  }
  &_title_description {
    width: 100%;
    padding: 1.5rem;
    @media screen and (max-width: 959px) {
      padding: 1rem;
    }
    @media screen and (max-width: 767px) {
      padding: 1rem;
      padding-bottom: 0rem;
    }
    p {
      margin-bottom: 0;
    }
  }
  &_title {
    font-size: 1.25rem;
    color: #1a2a40;
  }
  &_description {
    font-size: 1rem;
    color: #707070;
  }
  &_price_quantity {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    @media screen and (max-width: 767px) {
      flex-direction: row-reverse;
      border-left: 0px solid #ddd;
    }
    justify-content: space-between;
    align-items: center;
    min-width: 275px;
    border-left: 1px solid #ddd;
  }
  &_price {
    padding: 1rem 1rem;
    @media screen and (max-width: 767px) {
      padding: 0rem 1rem;
    }
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @media screen and (max-width: 767px) {
      justify-content: flex-end;
    }
    color: #707070;
    font-size: 1rem;
    b {
      font-size: 1.25rem;
      color: #1a2a40;
    }
  }
  &_quantity {
    padding: 1rem 1rem 1rem;
    flex: 1;
    @media screen and (max-width: 959px) {
      padding: 1rem;
      flex: 0;
    }
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > div:nth-child(odd) {
    background: #f5f5f5;
  }
}

.results_add {
  box-sizing: border-box;
  display: flex;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  align-items: center;
  border-top: 1px solid #dfdfdf;
  &_subtotal {
    padding: 0rem 2rem;
    @media screen and (max-width: 959px) {
      padding: 0rem 1rem;
    }
    @media screen and (max-width: 767px) {
      padding: 1rem;
    }
    flex: 1;
    align-items: baseline;
    color: #1a2a40;
    font-size: 1.25rem;
    b {
      font-size: 1.75rem;
      color: #2370a1;
    }
  }
  &_button {
    flex: 0;
    @media screen and (max-width: 767px) {
      flex: 1;
      width: 100%;
    }
    min-width: 275px;
    button {
      padding: 1.25rem 1rem;
      @media screen and (max-width: 959px) {
        padding: 1rem 1rem;
        font-size: 1rem;
      }
      width: 100%;
      background-color: var(--result_button_primary_background, #034a94);
      color: var(--result_button_primary_font_color, #fff);
      border: none;
      font-size: 1.325rem;
      font-weight: bold;
    }
    button:disabled {
      filter: saturate(0);
      cursor: not-allowed;
    }
  }
}

.quantity-selector {
  display: flex;
  button {
    padding: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: #dfdfdf;
    color: #1a2a40;
  }
  select {
    border: 1px solid #dfdfdf;
    padding: 0.75rem;
    cursor: pointer;
  }
}

.date_search {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
}

.loading-results {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.275rem;
  color: #555;
  padding: 3rem 1rem;
  max-width: 1280px;
  margin: 0 auto 3rem;
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #dfdfdf;
  background: #fff;
  .search-widget {
    width: 100%;
  }
}

.search-dates {
  color: var(--basket_price_font_color, #236ca3);
}

.edit-search {
  background: #e9e7e7;
  box-shadow: 0 2px 2px 0 #0000002e;
  border: 0px;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  font-weight: bold;
  color: #236ca3;
}

.datetime-message {
  font-size: 0.9rem;
  padding: 0.75rem 1rem;
  text-align: center;
  width: 100%;
  background: #f5f5f5;
  border-top: 1px solid #dfdfdf;
}

.results-continue {
  @media screen and (max-width: 768px) {
    padding: 0 1rem;
    margin: 2rem 0 3rem;
  }
  &__button {
    width: 100%;
    background-color: var(--result_button_primary_background, #034a94);
    color: var(--result_button_primary_font_color, #ffffff);
    border-radius: var(--result_button_primary_border_radius, 8px);
    box-shadow: 0px 3px 6px #00000029;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1.325rem;
    font-weight: bold;
    margin-bottom: 4rem;
  }
}
</style>
