<template>
  <div class="footer_container">
    <div class="footer-wrapper-top">
      <div class="footer-inner-top">
        <div class="footer-links" :class="columns">
          <!-- <a href="/index.html">HOME</a> -->
          <!-- <div v-for="links in footer_links" :key="links.group">
            <div class="footer-links-heading">{{ links.group }}</div>
            <a
              v-bind:href="a.url"
              :key="a.mid"
              class="footer-links-main"
              :target="[
                a.mtype && a.mtype.toLowerCase() === 'external'
                  ? '_blank'
                  : '_self',
              ]"
              v-for="a in links.data"
              >{{ a.label }}</a
            >
          </div>
       -->

          <div class="chevron">
            <div class="footer-links-heading">AT THE AIRPORT</div>
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/terminal-map/"
              title="Terminal Map"
              >Terminal Map</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/car-parking/"
              title="Car Parking"
              >Car Parking</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/airport-development-fee/"
              title="Airport Development Fee"
              >Airport Development Fee</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/fast-track-security/"
              title="Fast Track Security"
              >Fast Track Security</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/executive-lounge/"
              title="Executive Lounge"
              >Executive Lounge</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/shop-eat/"
              title="Shop &amp; Eat"
              >Shop &amp; Eat</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/corporate-aviation/"
              title="Corporate Aviation"
              >Corporate Aviation</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/accommodation/"
              title="Accommodation"
              >Accommodation</a
            >
          </div>

          <div class="chevron">
            <div class="footer-links-heading">ABOUT</div>
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/about-norwich-airport/"
              title="About Us"
              >About Us</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/regional-city-airports/"
              title="Regional &amp; City Airports"
              >Regional &amp; City Airports</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/rigby-group/"
              title="Rigby Group"
              >Rigby Group</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/fees-charges/"
              title="Fees &amp; Charges "
              >Fees &amp; Charges
            </a>
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/airport-fuelling/"
              title="Airport Fuelling"
              >Airport Fuelling</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/masterplan/"
              title="Masterplan"
              >Masterplan</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/corporate-social-responsibilities/"
              title="Corporate &amp; Social Responsibilities "
              >Corporate &amp; Social Responsibilities
            </a>
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/net-zero-2050/"
              title="Net Zero 2050"
              >Net Zero 2050</a
            >
          </div>

          <div class="chevron">
            <div class="footer-links-heading">INFORMATION</div>
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/arrivals-departures/"
              title="Arrivals &amp; Departures"
              >Arrivals &amp; Departures</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/passenger-information/"
              title="Passenger Information &amp; FAQ's"
              >Passenger Information &amp; FAQ's</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/special-assistance/"
              title="Special Assistance "
              >Special Assistance
            </a>
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/to-and-from-the-airport/"
              title="To/From the Airport "
              >To/From the Airport
            </a>
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/flights-holidays/"
              title="Flights &amp; Holidays "
              >Flights &amp; Holidays
            </a>
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/car-park-terms-conditions/"
              title="Car Parking T's &amp; C's"
              >Car Parking T's &amp; C's</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/wifi-pci-compliance/"
              title="Wi-Fi PCI Compliance "
              >Wi-Fi PCI Compliance
            </a>
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/airfield-pilot-information/"
              title="Fireworks, Drones &amp; Cranes"
              >Fireworks, Drones &amp; Cranes</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/covid-19-testing/"
              title="Covid-19 Passenger Information"
              >Covid-19 Passenger Information</a
            >
          </div>

          <div class="chevron">
            <div class="footer-links-heading">OTHER</div>
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/contact/"
              title="Contacts"
              >Contacts</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/news/"
              title="Airport News "
              >Airport News
            </a>
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/careers-and-vacancies/"
              title="Vacancies &amp; Careers"
              >Vacancies &amp; Careers</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/airfield-pilot-information-2/"
              title="Airfield &amp; Pilot Information"
              >Airfield &amp; Pilot Information</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/newsletter-signup/"
              title="Sign up for our newsletter"
              >Sign up for our newsletter</a
            >
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/tactical-command-documentation/"
              title="Staff Portal (password protected)"
              >Staff Portal (password protected)</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrapper-top footer-wrapper-top-extra">
      <div class="footer-inner-top">
        <div class="footer-links footer-links-extra" :class="columns">
          <div>
            <div class="footer-links-heading">VIEWS</div>
            <a
              class="footer-links-main commenting"
              target="_blank"
              href="https://www.norwichairport.co.uk/contact/"
              title="Contact"
              ><i class="far fa-comment-dots"></i>&nbsp;&nbsp;Tell us what you
              think</a
            >
          </div>

          <div>
            <div class="footer-links-heading">IN THE KNOW</div>
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/newsletter-signup/"
              title="Newsletter"
              ><i class="fas fa-newspaper"></i>&nbsp;&nbsp;Sign up for our
              newsletter</a
            >
          </div>

          <!-- <div>
            <div class="footer-links-heading">LATEST NEWS</div>
            <a
              class="footer-links-main"
              target="_blank"
              href="https://www.norwichairport.co.uk/norwich-airport-bounces-back-with-start-of-holiday-season/"
              title="Contacts"
              >Norwich Airport bounces back with start of holiday season</a
            >
          </div> -->

          <div>
            <div class="footer-links-heading">SOCIAL</div>
            <div style="display: flex">
              <a
                class="footer-links-main"
                target="_blank"
                href="https://twitter.com/@NorwichAirport"
                title="Twitter"
                ><i class="fab fa-twitter"></i
              ></a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a
                class="footer-links-main"
                target="_blank"
                href="https://www.facebook.com/NorwichInternationalAirport"
                title="Facebook"
                ><i class="fab fa-facebook-square"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-wrapper-bottom">
      <div class="footer-inner-bottom">
        <span>© {{ new Date().getFullYear() }} Norwich Airport . All Rights Reserved · Norwich Airport, Norwich NR6 6JA - Powered by Rezcomm</span>
        <span class="policy-links">
          <a
            target="_blank"
            href="https://www.norwichairport.co.uk/cookie-policy/"
            >Cookie Policy</a
          >
          <a
            target="_blank"
            href="https://www.norwichairport.co.uk/privacy-policy/"
            >Privacy Policy</a
          >
          <a
            target="_blank"
            href="https://www.norwichairport.co.uk/terms-of-use/"
            >Terms of Use</a
          >
        </span>
      </div>
    </div>
    <div style="clear: both"></div>
  </div>

  <div 
    class="cookie-settings-float" 
    :style="cookieBackgroundImage()"
    @click="toggle_cookie_settings()"
    >
    <span><i class="fas fa-cookie-bite"></i>&nbsp;&nbsp;Cookie settings</span>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    footer_links: undefined,
    footer_columns: undefined,
  },
  computed: {
    columns: function () {
      return "grid-" + this.footer_columns.toLowerCase().replaceAll(" ", "-");
    },
  },
  data() {
    return {
      copyright:
        "© 2022 Norwich Airport · All Rights Reserved · Norwich Airport, Norwich NR6 6JA - Powered by Rezcomm",
      poweredby: "",
    };
  },
  methods: {
    cookieBackgroundImage() {
      return `background-image: url(${require(`@/assets/images/cookie_option.svg`)});`;
    },
    toggle_cookie_settings() {
       const new_event = new CustomEvent("toggle_cookie_settings", {
        detail: {
          data: "toggle",
        },
      });
      window.dispatchEvent(new_event);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

.footer_container {
  height: auto;
  position: relative;
}

.footer-wrapper-top,
.footer-wrapper-bottom {
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
}

.footer-wrapper-top {
  border-top: $footer-top-border-top;
  background-color: #112039;
  color: $footer-top-text;
  font-family: $footer-top-font;
}

.footer-wrapper-bottom {
  // border-top: $footer-bottom-border-top;
  background-color: #101e34;
  color: $footer-bottom-text;
  font-family: $footer-bottom-font;
}

.footer-inner-top {
  max-width: calc(1280px + 2rem);
  margin: 0 auto;
  display: flex;
  .footer-links {
    padding: 60px 0;
    display: grid;
    width: 100%;
    padding: 2rem 1rem 1rem;

    &.grid-1-columns {
      grid-template-columns: 1fr;
    }

    &.grid-2-columns {
      grid-template-columns: 1fr 1fr;
    }

    &.grid-3-columns {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &.grid-4-columns {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    a {
      margin: 10px 0;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr !important;
      a {
        margin: 10px 0;
      }
    }

    &-heading {
      color: #ddd;
      font-size: 0.6875rem;
      font-weight: 400;
      letter-spacing: 2px;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }

    &-main {
      display: block;
    }
  }
  .footer-links a {
    color: $footer-top-text;
    text-decoration: none;
    // padding: 0 30px 0 0;
    padding: 0rem 0 0rem 0rem;
    font-size: 0.9rem;
    &:hover {
      text-decoration: underline;
    }
    @media screen and (max-width: 768px) {
      padding: 0 10px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.footer-links .chevron a {
  padding: 0rem 0 0rem 1rem;
  &:before {
    content: "\f105";
    font-family: "Font Awesome\ 5 Free";
    margin-left: -1em;
    margin-right: 0.5em;
    display: inline-block;
    font-weight: 900; /* Fix version 5.0.9 */
  }
}

.footer-wrapper-top-extra {
  border-top: 0px;
  background-color: #101e34;
}

.footer-links-extra.footer-links-extra {
  i {
    font-size: 1.25rem;
  }
  a {
    font-size: 1rem;
  }
}

.footer-inner-bottom {
  padding: 30px 10px;
  max-width: calc(1280px + 2rem);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: $footer-bottom-text;
  font-size: 0.825rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 30px 30px;
    span {
      padding: 20px 0;
    }
  }
}

.policy-links {
  a {
    color: #fff;
    padding: 0 1.5rem 0 0;
  }
}


.cookie-settings-float {
  position: fixed;
  bottom: 2rem;
  left: 0;
  padding: 10px 20px;
  border-top: none;
  border-left: none;
  cursor: pointer;
  z-index: 99999999;
  display: flex;
  flex-wrap: nowrap;
  align-self: flex-start;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.2);
  span {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    color: #fff;
  }
}
</style>
