<template>
  <!-- template for the modal component -->
  <div class="modal-mask">
    <div class="modal-wrapper" @click.self="$emit('close')">
      <div class="modal-container" :style="`width: ${modalWidth}; max-height: 90vh`">
        <div class="modal-container-inner">
          <div class="modal-header">
            <slot name="header"></slot>
            <i @click="$emit('close')" class="fa fa-times"></i>
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Model",
  props: {
    modalWidth: String,
  },
  created() {
    window.document.querySelector('body').setAttribute('style', 'overflow-y: hidden;');
  },
  unmounted() {
    window.document.querySelector('body').removeAttribute('style');
  }
};
</script>

<style lang="scss">


.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  overflow-y: scroll;
}

.modal-wrapper {
  position: relative;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow-y: auto;
}

.modal-container {
  max-width: calc(100vw - 1rem);
  width: auto;
  height: auto;
  margin: 0 auto;
  padding: 10px;

  @media screen and (min-width: 992px) {
    padding: 20px 30px;
    max-width: 80vw;
  }

}

.modal-container-inner {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  overflow: hidden;
}

.modal-header {
  display: flex;
  padding: 0px;
  position: relative;
  border: 0px solid #fff;
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-size: 1rem;
    color: #000;
    padding: 20px;
    text-align: center;
    width: 100%;
    font-weight: 700;
  }
  i {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 22px;
    cursor: pointer;
    font-size: 1rem;
  }
}

.modal-body {
  margin: 0;
  border: 0px solid #fff;
  padding: 0;
  iframe {
    width: 100%;
    height: 100%;
    border: 0px solid #fff;
    padding: 0;
    margin: 0;
  }
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}

.modal-footer {
  border: 0px solid #fff;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
