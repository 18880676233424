<template>
  <div
    class="header-wrapper"
    :style="set_style_variables"
  >
    <div class="header-inner">
      <div
        class="ID-logo"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderBasic",
  props: {
    logo_image: undefined,
  },
  computed: {
    set_style_variables: function () {
      return {
        "--logo-image": `url(${this.logo_image})`,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";
.header-wrapper {
  border-bottom: var(--header_border_bottom, $header-border-bottom);
  background: var(--header_background_color, $header-background);
  margin: 0;
  padding: 0;
  height: var(--header_height_desktop, 80px);
  @media screen and (max-width: 959px) {
    height: var(--header_height_mobile);
  }
  width: 100%;
  position: relative;
  box-sizing: content-box;
  .header-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-width: calc(1280px + 2rem);
    margin: 0 auto;
  }
  .ID-logo {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 33%;
    height: 100%;
    max-height: var(--logo_height_desktop);
    @media screen and (max-width: 959px) {
      max-height: var(--logo_height_mobile);
      width: 66%;
    }
    margin: 4px 1rem;
    box-sizing: border-box;
    position: relative;
    background-image: var(--logo-image);
    cursor: pointer;
  }
}
</style>
