<template>
  <div style="width: 100%">
    <vueper-slides
      :arrows-outside="false"
      :bullets-outside="false"
      class="no-shadow"
      :visible-slides="4"
      slide-multiple
      :gap="0"
      :slide-ratio="1 / 6"
      :touchable="true"
      :dragging-distance="50"
      :breakpoints="{
        800: { visibleSlides: 1, slideMultiple: 1, slideRatio: 0.5},
      }"
    >
      <vueper-slide v-for="slide in slides" :key="slide" :image="slide">
      </vueper-slide>
    </vueper-slides>
  </div>
</template>
<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  name: "SlideShow",
  components: { VueperSlides, VueperSlide },
  props: {
    images: Array,
  },
  computed: {
    slides() {
      return this.images.filter((image) => {
        return image;
      });
    },
  },
};
</script>
<style lang="scss">
.vueperslides {
  display: block;
  height: auto;
  aspect-ratio: 6 / 1;
  @media screen and (max-width: 800px) {
    aspect-ratio: 2 / 1;
  }
  .vueperslide {
    //background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    &__title {
      font-size: 7em;
      opacity: 0.6;
    }
  }
  .vueperslides__bullet .default {
    box-shadow: 0 0 1px #00000080, 0 0 3px #00000080;
  }
}
</style>
