<template>
  <div class="yourdetails-banner">
    <div class="yourdetails-banner__heading">
      <h1 class="yourdetails-banner__heading--desktop">{{ headline }}</h1>
      <h1 class="yourdetails-banner__heading--mobile">{{ headline_mobile }}</h1>
      <p v-if="subheadline">
        {{ subheadline }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "YourDetailsBanner",
  props: {
    headline: undefined,
    headline_mobile: undefined,
    subheadline: undefined,
  },
  computed: {},
  methods: {},
  updated() {},
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";
.yourdetails-banner {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__heading {
    color: #ffffff;

    h1 {
      font-size: 1.375rem;
      font-weight: 700;
    }

    p {
      font-size: 1.16rem;
      font-weight: 600;
    }

    &--desktop {
      display: none;
      @media screen and (min-width: 960px) {
        display: block;
      }
    }

    &--mobile {
      font-size: 1rem;
      display: block;
      @media screen and (min-width: 960px) {
        display: none;
      }
    }

    @media screen and (min-width: 768px) {
      h1 {
        font-size: 1.875rem;
        margin-top: 0;
      }

      p {
        font-size: 1.375rem;
        margin-bottom: 0;
      }
    }
  }
}
</style>
