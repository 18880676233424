
// slidedown
var slideDown = function(target, duration) {
  if (duration === undefined) {
    duration = 300;
  }
  if (!target) {
    return false;
  }
  if (window.getComputedStyle(target).display !== 'none') {
    return false;
  }
  if (target.getAttribute('data-slide-toggling') === 'true') {
    return false;
  }
  target.setAttribute('data-slide-toggling', true);  
  target.style.removeProperty('display');
  var display = window.getComputedStyle(target).display;
  if (display === 'none') display = 'block';
  target.style.display = display;
  var height = target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  var prevOffSetHeight = target.offsetHeight;
  target.offsetHeight = prevOffSetHeight;
  target.style.boxSizing = 'border-box';
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = duration + 'ms';
  target.style.height = height + 'px';
  target.style.removeProperty('padding-top');
  target.style.removeProperty('padding-bottom');
  target.style.removeProperty('margin-top');
  target.style.removeProperty('margin-bottom');
  window.setTimeout(function () {
    target.style.removeProperty('height');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
    target.removeAttribute('data-slide-toggling');  
  }, duration);
};

// slideup
var slideUp = function(target, duration) {
  if (duration === undefined) {
    duration = 300;
  }
  if (!target) {
    return false;
  }
  if (window.getComputedStyle(target).display === 'none') {
    return false;
  }
  if (target.getAttribute('data-slide-toggling') === 'true') {
    return false;
  }
  target.setAttribute('data-slide-toggling', true);  
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = duration + 'ms';
  target.style.boxSizing = 'border-box';
  target.style.height = target.offsetHeight + 'px';
  var prevOffSetHeight = target.offsetHeight;
  target.offsetHeight = prevOffSetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  window.setTimeout(function () {
    target.style.display = 'none';
    target.style.removeProperty('height');
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
    target.removeAttribute('data-slide-toggling'); 
  }, duration);
};

// slide toggle
var toggle = function(target, duration) {
  if (!target) {
    return false;
  }
  if (duration === undefined) {
    duration = 300;
  }
  if (target.getAttribute('data-slide-toggling') === 'true') {
    return false;
  }
  if (window.getComputedStyle(target).display === 'none') {
    return slideDown(target, duration);
  } else {
    return slideUp(target, duration);
  }
};
// export
if (typeof exports != "undefined") {
  exports.slide = {
    up: slideUp,
    down: slideDown,
    toggle: toggle
  };
} else {
  window.slide = {
    up: slideUp,
    down: slideDown,
    toggle: toggle
  };
}