<template>
	<div class="yourdetails-banner">
		<div class="yourdetails-banner__heading">
			<h1>{{ headline }}</h1>
			<p v-if="subheadline">
				{{ subheadline }}
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'YourDetailsBanner',
		props: {
			headline: undefined,
			subheadline: undefined,
		},
		computed: {},
		data() {},
		methods: {},
		updated() {},
	};
</script>

<style scoped lang="scss">
	@import '@/assets/sass/variables.scss';
	.yourdetails-banner {
		max-width: 1280px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
    text-align: center;

		@media screen and (min-width: 768px) {
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}

		&__heading {
			color: #ffffff;

			h1 {
				font-size: 1.875rem;
				font-weight: 700;
			}

			p {
				font-size: 1.16rem;
				font-weight: 600;
			}

			@media screen and (min-width: 768px) {
				h1 {
					font-size: 1.875rem;
					margin: 0;
				}

				p {
					font-size: 1.375rem;
					margin-bottom: 0;
				}
			}
		}
	}
</style>
